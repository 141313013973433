.data-table {
  .cds--toolbar-content,
  .cds--search-input,
  .cds--search-input:hover,
  .cds--search-input:focus,
  td,
  tr,
  &__pagination {
    background-color: white;
    height: 48px;
  }

  .cds--data-table-header {
    padding-left: 0;
  }
}
