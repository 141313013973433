* {
  box-sizing: border-box;
}

html,
body, #root {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.cds--modal .cds--dropdown,
.cds--modal .cds--text-input {
  background-color: $gray-10;
}

.cds--modal-content--overflow-indicator {
  background-image: none;
}

.cds--modal-container {
  background-color: white;
}

.cds--date-picker,
.cds--date-picker-container,
.cds--date-picker-input__wrapper,
.cds--date-picker__input {
  width: 100% !important;
}

.cds--date-picker__input {
  background-color: $gray-10 !important;
}

.cds--file-browse-btn {
  max-width: unset !important;
}

input:focus,
select:focus,
textarea:focus,
button:focus,
.cds--structured-list-row,
.cds--toggle__switch,
.cds--side-nav__link,
.cds--modal-close {
  outline: 0px solid transparent !important;
  outline-offset: 2px !important;
  box-shadow: none !important;
  outline-width: 0px !important;
  border: none;
}