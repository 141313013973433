.page-layout {
  height: 100vh;

  &__body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    &-content {
      min-height: 100%;
      margin: 0;
      padding: 72px 40px 72px 300px;
      background-color: $gray-10;

      @include breakpoint-down(lg) {
        padding: 72px 16px;
      }

      &-children {
        padding: 0;
        color: $gray-100;
      }
    }
  }
}
