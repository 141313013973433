.cds--overflow-menu--flip {
    flex-direction: row;
    width: auto;
    flex-wrap: wrap;
    width: 500px;
    max-width: 700px;
    .overflow-menu-sections {
        // padding: 10px 20px;
        display: flex;
        width: 100%;
        .overflow-menu-section {
            
            width: 50%;
            .cds--label {
                padding: 10px 10px;
            }
        }
    }
    .bx-overflow-menu-cta {
        display: flex;
        width: 100%;
        .bx-btn {
            width: 50%;
        }
        .bx-btn:first-of-type {
            background-color: #161616;
        }
    }
}